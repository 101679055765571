import { Field, makeField } from './fields'

export enum PageColumnLink {
  None = 0,
  IndividualSidebar = 1,
  EntityPage = 2,
  EntitySidebar = 3
}

export enum PageColumnType {
  Text = 0,
  Number = 1,
  ShortDate = 2,
  LongDate = 3,
  Currency = 4,
  Email = 5,
  Phone = 6
}

export class PageColumn {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public variable: string,
    public textVariable: string,
    public commentsVariable: string,
    public field: Field|null,
    public subColumns: string[],
    public subLabels: string[],
    public hideFilter: boolean,
    public style: string,
    public maxWidth: string,
    public link: PageColumnLink,
    public columnType: PageColumnType
  ) {
  }
}

export function makePageColumn(jsonData: any = null): PageColumn {
  if (jsonData === null) {
    jsonData = {}
  }
  let subColumns: string[] = []
  if (jsonData.sub_columns) {
    subColumns = jsonData.sub_columns.split(',')
  }
  let subLabels: string[] = []
  if (jsonData.sub_labels) {
    subLabels = jsonData.sub_labels.split(',')
  }
  return new PageColumn(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.label || '',
    jsonData.variable || '',
    jsonData.text_variable || '',
    jsonData.comments_variable || '',
    jsonData.field ? makeField(jsonData.field) : null,
    subColumns,
    subLabels,
    !!jsonData.hide_filter,
    jsonData.style || '',
    jsonData.max_width || '',
    jsonData.link || PageColumnLink.None,
    jsonData.column_type || PageColumnType.Text
  )
}

export class ApiFilter {
  constructor(
    public id: number,
    public name: string,
    public apiUrl: string,
    public apiVariable: string,
    public queryString: string,
    public isMultiple: boolean,
    public onlyLastAdhesion: boolean
  ) {
  }
}

export function makeApiFilter(jsonData: any = null): ApiFilter {
  if (jsonData === null) {
    jsonData = {}
  }
  return new ApiFilter(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.api_url || '',
    jsonData.api_variable || '',
    jsonData.query_string || '',
    !!jsonData.is_multiple,
    !!jsonData.only_last_adhesion
  )
}

export class PageAction {
  constructor(
    public id: number,
    public name: string,
    public icon: string,
    public title: string,
    public confirmText: string,
    public apiUrl: string,
    public permissions: string[],
    public selectField: string,
    public selectPostField: string,
    public postData: any
  ) {
  }
}

export function makePageAction(jsonData: any = null): PageAction {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PageAction(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.icon || '',
    jsonData.title || '',
    jsonData.confirm_text || '',
    jsonData.api_url || '',
    (jsonData.permissions || '').split(' '),
    jsonData.select_field || '',
    jsonData.select_post_field || '',
    jsonData.post_data || {}
  )
}

export class Page {
  constructor(
    public id: number,
    public name: string,
    public icon: string,
    public slug: string,
    public apiUrl: string,
    public columns: PageColumn[],
    public apiFilters: ApiFilter[],
    public actions: PageAction[],
    public loadOnFilter: boolean,
    public landscape: boolean
  ) {
  }
}

export function makePage(jsonData: any = null): Page {
  if (jsonData === null) {
    jsonData = {}
  }
  const columns: any[] = jsonData.columns || []
  const apiFilters: any[] = jsonData.api_filters || []
  const actions: any[] = jsonData.actions || []
  return new Page(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.icon || '',
    jsonData.slug || '',
    jsonData.api_url || '',
    columns.map((elt: any) => makePageColumn(elt)),
    apiFilters.map((elt: any) => makeApiFilter(elt)),
    actions.map((elt: any) => makePageAction(elt)),
    !jsonData.load_on_init && apiFilters.length > 0,
    !!jsonData.landscape
  )
}
